import { useEffect, useState } from "react";

import { i18n } from "i18next";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { configSectionGlobalSelector } from "../../providers/config/selectors";
import logger from "../logger-utils";
import {
  getInstanceImmediate,
  I18NEXT_SESSION_STORAGE_PREFIX_CACHE,
} from "./i18next/i18next-instance";

const instance: i18n = getInstanceImmediate();

export default function useI18nLanguageSwitch(): {
  instance?: i18n;
} {
  const router = useRouter();
  const [initializedInstance, setInitializedInstance] = useState<i18n | undefined>();
  const globalConfiguration = useRecoilValue(configSectionGlobalSelector);
  // No safer alternative than using an effect to detect a locale update
  useEffect(() => {
    let locale: string;
    const availableLocaleList =
      globalConfiguration?.pages?.locales?.map((locale) => locale.valueOf()) || [];
    if (router.locale && availableLocaleList.includes(router.locale)) {
      locale = router.locale;
    } else if (globalConfiguration?.pages?.defaultLocale) {
      locale = globalConfiguration?.pages?.defaultLocale;
      router.defaultLocale = locale;
    } else if (router.defaultLocale && availableLocaleList.includes(router.defaultLocale)) {
      locale = router.defaultLocale;
    } else if (availableLocaleList.length > 0) {
      locale = availableLocaleList[0];
    } else {
      locale = router.locale || router.defaultLocale || "";
    }
    instance.changeLanguage(locale, () => {
      logger.info("i18n locale loaded", locale);

      // Set instance only when locale have been loaded
      setInitializedInstance(instance);
    });
  }, [
    globalConfiguration?.pages?.defaultLocale,
    globalConfiguration?.pages?.locales,
    router,
    router.defaultLocale,
    router.locale,
  ]);

  return { instance: initializedInstance };
}

export const useLocalStorageCache = (): {
  clear: () => Promise<void>;
} => {
  const { i18n } = useTranslation();
  const clear = async () => {
    Object.keys(sessionStorage)
      .filter((x) => x.startsWith(I18NEXT_SESSION_STORAGE_PREFIX_CACHE))
      .forEach((x) => sessionStorage.removeItem(x));
    await i18n.reloadResources();
  };
  return {
    clear,
  };
};
